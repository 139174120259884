.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #00a8ff;
  color: white;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s;
  z-index: 1;
}

.scroll-to-top-button:hover {
  background-color: #007acc;
}

.scroll-to-top-button span {
  display: inline-block;
  transform: translateY(0);
  transition: transform 0.3s;
}

.scroll-to-top-button:hover span {
  transform: translateY(-5px);
}
