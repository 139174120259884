.contact-us-page {
  max-width: 1400px;
  margin: 0 auto;
  padding: 10px;
  text-align: center;
  margin-top: 70px;
  font-family: "Arial", sans-serif;
}

.colorful-bar {
  background: linear-gradient(90deg, rgb(49, 149, 189) 0%, #00c9ff 100%);
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.contact-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.details-container {
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
}

.details-container h1 {
  color: #1a3a6e;
  margin-bottom: 20px;
  font-size: 2rem;
  animation: fadeIn 1s ease-in-out;
}

.details-container p {
  font-size: 1.1rem;
  margin: 5px 0;
  color: #333;
}

.map-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.contact-form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-container {
  width: 100%;
  max-width: 600px;
  background-color: #f0f8ff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  animation: slideUp 0.6s ease-in-out;
}

.form-container h2 {
  color: #1a3a6e;
  margin-bottom: 20px;
  font-size: 1.8rem;
}

.form-container input,
.form-container textarea {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s;
}

.form-container input:focus,
.form-container textarea:focus {
  border-color: #00a8ff;
  outline: none;
}

.form-container button {
  padding: 12px 24px;
  background-color: #00a8ff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.form-container button:hover {
  background-color: #007acc;
}

.thank-you-message {
  text-align: center;
  padding: 20px;
  background-color: #e0f7fa;
  border-radius: 10px;
  margin-top: 20px;
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
