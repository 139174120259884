.modal-overlay {
  position: fixed;
  top: -400px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
  width: 90%;
  position: relative;
  text-align: center;
}

.modal-content img {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
}

.modal-content h3 {
  color: #1a3a6e;
  margin-bottom: 10px;
}

.modal-content p {
  color: #333;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ff5c5c;
  border: none;
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 1.5rem;
  cursor: pointer;
}
